<template>
  <layout>
    <v-progress-linear
      v-if="updatedElements && totalElements && (updatedElements < totalElements)"
      :value="(updatedElements*100)/totalElements"
    ></v-progress-linear>
    <v-container fluid v-if="movie">
      <v-speed-dial
        v-if="isAdmin"
        v-model="fab"
        fixed
        bottom
        right
        direction="top"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="blue darken-2"
            dark
            fab
          >
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-cogs</v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          small
          color="red"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color="indigo"
          @click="updateRatings"
        >
          <v-icon>mdi-star-half</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color="green"
          @click="update"
          :loading="updatedElements < totalElements"
        >
          <v-icon>mdi-update</v-icon>
        </v-btn>
      </v-speed-dial>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col md="3" lg="2" v-if="$vuetify.breakpoint.mdAndUp && movie.poster_path">
              <img style="width: 100%;" v-lazy="imgObj(movie.poster_path)">
            </v-col>
            <v-col cols="12" md="9" lg="10">
              <v-row>
                <v-col cols="12">
                  <h1 style="font-weight: bolder;">
                    <p class="my-0 py-0" v-if="translation && translation.title">{{ translation.title }}</p>
                    <p class="my-0 py-0" v-else>{{ movie.title }}</p>
                  </h1>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0" cols="12">
                  <h5>
                    <b v-if="movie.release_date" v-text="release_date.format('Y')"></b>
                    |
                    <span v-if="movie.runtime" v-text="formatTime(movie.runtime*60, false)"></span>
                  </h5>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-chip v-if="movie.vote_average">
                    <v-avatar tile left>
                      <v-img src="/img/tmdb.svg" width="25" height="25"></v-img>
                    </v-avatar>
                    &nbsp;{{ movie.vote_average }}/10
                  </v-chip>
                  <v-chip v-for="(rating, index) in ratings" :key="index">
                    <v-avatar tile left>
                      <v-img v-if="rating.Source == 'Internet Movie Database'" src="/img/imdb.png" width="20" height="20"></v-img>
                      <v-img v-if="rating.Source == 'Metacritic'" src="/img/metacritic.svg" width="25" height="25"></v-img>
                      <v-img v-if="rating.Source == 'Rotten Tomatoes' && rating.Value.slice(0, -1) > 60" src="/img/rotten-fresh.png" width="25" height="25"></v-img>
                      <v-img v-if="rating.Source == 'Rotten Tomatoes' && rating.Value.slice(0, -1) <= 60" src="/img/rotten-rotten.png" width="25" height="25"></v-img>
                    </v-avatar>
                    {{ rating.Value }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p v-if="translation && translation.overview">{{ translation.overview }}</p>
                  <p v-else>{{ movie.overview }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn v-if="(!watch || !watch.time) && movie && movie.id" tile :to="'/playmovie/'+movie.id" color="error">
                    <v-icon left>play_arrow</v-icon>
                    Play
                  </v-btn>
                  <v-btn v-if="watch && watch.time && movie && movie.id" tile :to="'/playmovie/'+movie.id" color="error">
                    <v-icon left>play_arrow</v-icon>
                    Continue at {{formatTime(watch.time)}}
                  </v-btn>
                  <!--<button v-if="!inList" class="btn btn-secondary" @click="togglelist()">
                    <i class="fas fa-plus"></i> My List
                  </button>
                  <button v-if="inList" class="btn btn-secondary" @click="togglelist()">
                    <i class="fas fa-check"></i> My List
                  </button>
                  <button v-if="!rating || rating.rating === 2" type="button"
                          class="btn btn-outline-light" @click="vote(2)">
                    <i class="fas fa-thumbs-up"></i>
                  </button>
                  <button v-if="!rating || rating.rating === 1" type="button"
                          class="btn btn-outline-light" @click="vote(1)">
                    <i class="fas fa-thumbs-down"></i>
                  </button>-->
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row v-if="director">
                <v-col cols="12">
                  Director:
                  <router-link :to="'/people/'+director.id">
                    <span v-text="director.name"></span>
                  </router-link>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" v-if="cast">
                  Starring:
                  <span v-for="(character, index) in cast" :key="index">
                    <router-link :to="'/people/'+character.id">{{character.name}}</router-link>
                                    <span v-if="index+1 < cast.length">, </span>
                                </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  Genres:
                  <span v-for="(genre, index) in movie.genres" :key="index">
                                    <span v-text="genre.name"></span><span
                    v-if="index+1 < movie.genres.length">, </span>
                                </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="collection">
            <v-col cols="12">
              <div class="row">
                <div class="col">
                  Collection: <span v-text="collection.name"></span>
                  <ul>
                    <li v-for="item in collection.movies" :key="item.id">
                      <router-link :to="'/movies/'+item.id" v-text="item.title"></router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
              </div>
            </v-col>
          </v-row>
          <v-row v-if="video" justify="center">
            <v-col cols="12" lg="8" md="10">
              <iframe class="embed-responsive-item"
                      :src="'https://www.youtube.com/embed/' + (video.source ? video.source : video.key) + '?rel=0'"
                      style="width: 100%; height:30vw;"
                      allowfullscreen></iframe>
            </v-col>
          </v-row>
          <!--
          <v-divider v-if="false"></v-divider>
          <div class="row" v-if="false">
            <div class="col">
              <div class="row">
                <div class="col">
                  <h2>Posters</h2>
                </div>
              </div>
              <v-slide-group
                v-model="posterModel"
                show-arrows
              >
                <v-slide-item
                  v-for="(poster, n) in posters"
                  :key="n"
                  v-slot:default="{ active, toggle }"
                >
                  <v-card
                    :color="active ? 'primary' : 'grey lighten-1'"
                    width="200"
                    max-width="400"
                    @click="toggle"
                  >
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                      style="width: 100%;"
                    >
                      <v-scale-transition>
                        <v-icon
                          style="position: absolute;"
                          v-if="active"
                          color="white"
                          size="48"
                          v-text="'mdi-close-circle-outline'"
                        ></v-icon>
                      </v-scale-transition>
                      <img v-lazy="imgObj(poster.file_path)"
                           height="100%"
                           width="100%"
                      />
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </div>
          </div>
          <v-divider v-if="false"></v-divider>
          <div class="row" v-if="false">
            <div class="col">
              <div class="row">
                <div class="col">
                  <h2>Backdrops</h2>
                </div>
              </div>
              <v-slide-group
                v-model="backdropModel"
                show-arrows
              >
                <v-slide-item
                  v-for="(backdrop, n) in backdrops"
                  :key="n"
                  v-slot:default="{ active, toggle }"
                >
                  <v-card
                    :color="active ? 'primary' : 'grey lighten-1'"
                    width="300"
                    @click="toggle"
                  >
                    <v-row
                      align="center"
                      justify="center"
                      style="width: 100%;"
                    >
                      <v-scale-transition>
                        <v-icon
                          v-if="active"
                          color="white"
                          size="48"
                          v-text="'mdi-close-circle-outline'"
                        ></v-icon>
                      </v-scale-transition>
                      <img v-lazy="imgObj(backdrop.file_path)"
                           height="100%"
                           width="100%"
                      />
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </div>
          </div>
          -->
        </v-col>
      </v-row>
    </v-container>
  </layout>
</template>

<script>
import Layout from '../Layout';
import moment from 'moment';
import axios from 'axios';
import numeral from 'numeral';
import utils from '../utils';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { moviedb } from '../variables';

export default {
  metaInfo () {
    if (this.translation && this.translation.title) {
      return {
        title: this.translation.title
      };
    }
    return {
      title: this.movie.title
    };
  },
  data () {
    return {
      isAdmin: false,
      totalElements: 0,
      updatedElements: 0,
      fab: false,
      posterModel: null,
      backdropModel: null,
      movie: {},
      backdrops: [],
      posters: [],
      cast: [],
      crew: [],
      collection: null,
      video: null,
      translation: null,
      ratings: [],
      director: null,
      watch: null,
      watchRef: null,
      watchSnapshot: null
    };
  },
  components: {
    Layout
  },
  computed: {
    release_date: function () {
      return moment(this.movie.release_date);
    },
    budget: function () {
      return this.formatPrice(this.movie.budget);
    },
    revenue: function () {
      return this.formatPrice(this.movie.revenue);
    }
  },
  methods: {
    imgObj (path) {
      return {
        src: 'https://assets.le-navet.com/static/300' + path,
        error: 'https://europe-west1-turnip-820de.cloudfunctions.net/importImage?image=' + path
      };
    },
    formatTime (time, withSeconds = true) {
      return utils.timeToString(time, withSeconds);
    },
    formatPrice (value) {
      return numeral(value).format('($ 0.00 a)');
    },
    update () {
      let firestore = firebase.firestore();
      let movieRef = firestore.collection('movies');

      this.totalElements = 0;
      this.updatedElements = 0;

      moviedb.movieInfo({ id: this.movie.id }).then(movieInfo => {
        movieRef.doc(this.movie.id + '').get().then(movie => {
          if (movie.exists) {
            if (!movie.data().date_added) {
              console.log('date added not found');
              movieInfo.date_added = firebase.firestore.Timestamp.fromDate(new Date())
            }
            movieRef.doc(this.movie.id + '').update(movieInfo);
          } else {
            movieInfo.date_added = firebase.firestore.Timestamp.fromDate(new Date());
            movieRef.doc(this.movie.id + '').set(movieInfo);
          }
        });
        moviedb.movieImages({ id: this.movie.id }).then(movieImages => {
          movieRef.doc(this.movie.id + '').collection('posters').get().then(posters => {
            if (posters.size === 0) {
              this.totalElements += movieImages.posters.length;
              for (let j = 0; j < movieImages.posters.length; j++) {
                movieRef.doc(this.movie.id + '').collection('posters').doc().set(movieImages.posters[j]).finally(() => {
                  this.updatedElements++;
                });
              }
            }
          });
          movieRef.doc(this.movie.id + '').collection('backdrops').get().then(backdrops => {
            if (backdrops.size === 0) {
              this.totalElements += movieImages.backdrops.length;
              for (let j = 0; j < movieImages.backdrops.length; j++) {
                movieRef.doc(this.movie.id + '').collection('backdrops').doc().set(movieImages.backdrops[j]).finally(() => {
                  this.updatedElements++;
                });
              }
            }
          });
        });
        /*
        moviedb.movieVideos({ id: this.movie.id }).then(movieVideos => {
          for (let j = 0; j < movieVideos.results.length; j++) {
            movieRef.doc(this.movie.id + '').collection('videos').doc(movieVideos.results[j].id).set(movieVideos.results[j]);
          }
        });
        */
        moviedb.movieTranslations({ id: this.movie.id }).then(movieTranslations => {
          movieRef.doc(this.movie.id + '').collection('translations').get().then(translations => {
            if (translations.size === 0) {
              this.totalElements += movieTranslations.translations.length;
              for (let j = 0; j < movieTranslations.translations.length; j++) {
                movieRef.doc(this.movie.id + '').collection('translations').doc().set(movieTranslations.translations[j]).finally(() => {
                  this.updatedElements++;
                });
              }
            }
          });
        });
        moviedb.movieCredits({ id: this.movie.id }).then(movieCredits => {
          movieRef.doc(this.movie.id + '').collection('cast').get().then(cast => {
            if (cast.size === 0) {
              this.totalElements += movieCredits.cast.length;
              for (let j = 0; j < movieCredits.cast.length; j++) {
                movieRef.doc(this.movie.id + '').collection('cast').doc(movieCredits.cast[j].id + '').set(movieCredits.cast[j]).finally(() => {
                  this.updatedElements++;
                });
              }
            }
          });
          movieRef.doc(this.movie.id + '').collection('crew').get().then(crew => {
            if (crew.size === 0) {
              this.totalElements += movieCredits.crew.length;
              for (let j = 0; j < movieCredits.crew.length; j++) {
                movieRef.doc(this.movie.id + '').collection('crew').doc(movieCredits.crew[j].id + '').set(movieCredits.crew[j]).finally(() => {
                  this.updatedElements++;
                });
              }
            }
          });
        });
        moviedb.movieVideos({ id: this.movie.id }).then(movieTrailers => {
          movieRef.doc(this.movie.id + '').collection('trailers').get().then(trailers => {
            if (trailers.size === 0) {
              this.totalElements += movieTrailers.results.length;
              for (let j = 0; j < movieTrailers.results.length; j++) {
                movieRef.doc(this.movie.id + '').collection('trailers').doc().set(movieTrailers.results[j]).finally(() => {
                  this.updatedElements++;
                });
              }
            }
          });
        });
        /*
        moviedb.movieSimilar({ id: this.movie.id }).then(movieSimilar => {
          for (let j = 0; j < movieSimilar.results.length; j++) {
            movieRef.doc(this.movie.id + '').collection('similar').doc(movieSimilar.results[j].id + '').set(movieSimilar.results[j]);
          }
        });
        */
        /*
        moviedb.movieRecommendations({ id: this.movie.id }).then(movieRecommendations => {
          for (let j = 0; j < movieRecommendations.results.length; j++) {
            movieRef.doc(this.movie.id + '').collection('recommendations').doc(movieRecommendations.results[j].id + '').set(movieRecommendations.results[j]);
          }
        });
        moviedb.movieKeywords({ id: this.movie.id }).then(movieKeywords => {
          this.totalElements += movieKeywords.keywords.length;
          for (let j = 0; j < movieKeywords.keywords.length; j++) {
            movieRef.doc(this.movie.id + '').collection('keywords').doc(movieKeywords.keywords[j].id + '').set(movieKeywords.keywords[j]).finally(() => {
              this.updatedElements++;
            });
          }
        });
         */
      });
    },
    updateRatings () {
      axios.get('https://www.omdbapi.com/?i=' + this.movie.imdb_id + '&apikey=e31fcfc4')
        .then(response => {
          let firestore = firebase.firestore();
          let movieRef = firestore.collection('movies');
          for (let i = 0; i < response.data.Ratings.length; i++) {
            movieRef.doc(this.movie.id + '').collection('ratings').doc(i + '').set(response.data.Ratings[i]);
          }
        })
    },
    initialize () {
      let language = this.$vuetify.lang.current;
      let movieId = this.$route.params.id;
      let firestore = firebase.firestore();
      let movieRef = firestore.collection('movies');
      movieRef.doc(movieId).get().then(querySnapshot => {
        this.movie = querySnapshot.data();
      });
      this.translation = null;
      movieRef.doc(movieId).collection('translations').where('iso_639_1', '==', language).get().then(querySnapshot => {
        querySnapshot.forEach((doc) => {
          if (language === 'fr' && doc.data().iso_3166_1 === 'FR') {
            this.translation = doc.data().data;
          } else if (language !== 'fr') {
            this.translation = doc.data().data;
          }
        });
      });
      this.cast = [];
      movieRef.doc(movieId).collection('cast').orderBy('order', 'asc').limit(10).get().then(querySnapshot => {
        this.cast = [];
        querySnapshot.forEach((doc) => {
          this.cast.push(doc.data());
        });
      });
      this.director = null;
      movieRef.doc(movieId).collection('crew').where('job', '==', 'Director').limit(1).get().then(querySnapshot => {
        querySnapshot.forEach((doc) => {
          this.director = doc.data();
        });
        if (!this.director) {
          movieRef.doc(movieId).collection('crew').where('job', '==', 'Screenplay').limit(1).get().then(querySnapshot2 => {
            querySnapshot2.forEach((doc) => {
              this.director = doc.data();
            });
          });
        }
      });
      this.video = null;
      movieRef.doc(movieId).collection('trailers').where('type', '==', 'Trailer').limit(1).get().then(snapshot => {
        snapshot.forEach(doc => {
          this.video = doc.data();
        });
      });
      this.ratings = [];
      movieRef.doc(movieId).collection('ratings').get().then(snapshot => {
        this.ratings = [];
        snapshot.forEach(doc => {
          this.ratings.push(doc.data());
        });
      });
      this.watchRef = firestore.collection('users').doc(firebase.auth().currentUser.uid).collection('movie_watches').doc(movieId + '');
      if (this.watchSnapshot) {
        this.watchSnapshot();
      }
      this.watchSnapshot = this.watchRef.onSnapshot(querySnapshot => {
        this.watch = querySnapshot.data();
      });
    }
  },
  watch: {
    $route () {
      this.initialize();
    }
  },
  beforeDestroy: function () {
    if (this.watchSnapshot) {
      this.watchSnapshot();
    }
  },
  mounted: function () {
    this.isAdmin = firebase.auth().currentUser.uid === 'YXV5Q5r7I0eKwHhfK8EcCp84V8I3';
  },
  created: function () {
    this.initialize();
  }
};
</script>
